import imgApropos from 'assets/images/home-apropos.jpg';
import logoConsulting from 'assets/images/home-consulting-icon.png';
import logoContentMarketing from 'assets/images/home-contentmarketing-icon.png';
import logoEReputation from 'assets/images/home-ereputation-icon.png';
import logoOutsourcing from 'assets/images/home-outsourcing-icon.png';
import logoStrategy from 'assets/images/home-strategy-icon.png';
import HomeHero from 'components/HomeHero';
import Map from 'components/Map';
import APPContext from 'config/context/APPContext';
import React from 'react';
import { Container, Grid, GridColumn, Image } from 'semantic-ui-react';

const Home = (props) => {
  const context = React.useContext(APPContext);
  React.useEffect(() => {
    context.setHero({ hero: <HomeHero />, activeMenu: '/' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="block apropos bg-dark-blue">
        <Container>
          <div className="header color-white">
            <h1>à propos de nous</h1>
          </div>
          <div className="content">
            <Grid columns="2">
              <GridColumn mobile={16} largeScreen={8}>
                <h1 className="color-white">NOTRE AGENCE</h1>
                <p className="color-white justify">
                Trade For World, c’est une aventure qui a commencé en décembre 2000 dans la charmante ville de Lausanne, en Suisse. Dans cet écrin paisible, nous avons posé les bases d’une agence qui allait évoluer au rythme des transformations du marketing digital.
                </p>
                <p className="color-white justify">
                Pionniers du digital dès 2012, nous avons accompagné nos premiers clients dans leur transition vers les réseaux sociaux, à une époque où cette présence était encore une nouveauté. Depuis, nous n’avons cessé d’évoluer : en enrichissant nos services, en renforçant notre expertise, et en nous adaptant aux besoins toujours changeants de nos clients.
                </p>
                <p className="color-white justify">
                En 2015, un tournant décisif s’est imposé. Le simple fait d’avoir une équipe multidisciplinaire composée de managers en marketing digital et de développeurs web ne suffisait plus. Nous avons alors créé une entité entièrement dédiée au succès de nos clients : une équipe d’experts, unie par un objectif commun – aider les entreprises à se développer et à prospérer dans un monde numérique en constante mutation.
                </p>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={8}>
                <Image src={imgApropos} />
              </GridColumn>
              <GridColumn mobile={16}>
                <p className="color-white justify">
                Pour nous, la communication digitale va bien au-delà de jolies photos sur Instagram ou Facebook. C’est un levier puissant et stratégique, conçu pour atteindre un objectif fondamental : faire croître les entreprises et optimiser leur performance.
                </p>
                <p className="color-white justify">
                Chez Trade For World, nous plaçons vos objectifs au cœur de tout ce que nous faisons. Nous analysons votre activité en profondeur, définissons une stratégie sur mesure, et vous accompagnons pour vous démarquer dans un environnement digital toujours plus compétitif.
                </p>
                <p className="color-white justify">
                <b>Votre succès est notre mission.</b>
                </p>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </section>
      <section className="block services">
        <Container>
          <div className="header ">
            <h1>Nos services</h1>
          </div>
          <div className="subheader "></div>
          <div className="content">
            <Grid columns="3">
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>
                      e-Reputation et <br /> e-Surveillance{' '}
                    </h1>
                    <Image src={logoEReputation} size="tiny" />
                  </div>
                  <div className="box-content">
                    Que vous soyez un établissement hôtelier, un restaurant, une régie immobilière, une école, un salon de coiffure ou cabinet dentaire; vous avez tous besoin de contrôler votre réputation en ligne (e-reputation). ... <a href="/services/e-reputation">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>
                      Content <br /> Marketing{' '}
                    </h1>
                    <Image src={logoContentMarketing} size="tiny" />
                  </div>
                  <div className="box-content">
                    Aujourd'hui vous ne devez pas seulement être présent sur le web, vous devez également intéresser votre client. Pour cela vous devez produire du contenu qui a de la valeur et ainsi l'inciter à vouloir ... <a href="/services/content-marketing">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>Stratégie </h1>
                    <Image src={logoStrategy} size="tiny" />
                  </div>
                  <div className="box-content">
                    Vous êtes une PME genevoise, vaudoise, neuchâteloise ou valaisanne et vous pensez qu'il est l'heure de moderniser votre marketing digital et de passer à la vitesse supérieure. ... <a href="/services/strategy">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>Consulting </h1>
                    <Image src={logoConsulting} size="tiny" />
                  </div>
                  <div className="box-content">
                    Si vous souhaitez des conseils quant à votre stratégie de marketing digital ou si vous avez besoins d’analyses concernant votre image de marque, la performance de vos actions en ligne, l'implémentation de nouveaux outils (CRM, ERP...). ... <a href="/services/consulting">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>Outsourcing </h1>
                    <Image src={logoOutsourcing} size="tiny" />
                  </div>
                  <div className="box-content">
                    Vous êtes une PME genevoise, vaudoise, neuchâteloise ou valaisanne et vous cherchez à optimiser votre marketing digital. Vous cherchez à optimiser votre marketing digital. Vous voulez ... <a href="/services/outsourcing">continuer</a>
                  </div>
                </div>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </section>
      <Map />
    </>
  );
};

export default Home;
