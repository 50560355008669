import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import Navigation from './Navigation';
import Typing from 'react-typing-animation';
import { createMedia } from '@artsy/fresnel';
import { Link } from 'react-router-dom';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const HomeHero = (props) => {
  return (
    <section className="hero home">
      <Container>
        <MediaContextProvider>
          <Media at="computer">
            <Navigation {...props} />
          </Media>
        </MediaContextProvider>
        <div className="content">
          <Typing>
            <h1>
              Votre <b>Partenaire</b> en Marketing Digital
            </h1>
          </Typing>
          <h3>Nous sommes une agence de communication digitale indépendante, spécialisée dans la stratégie digitale, le branding, le design, et la création de contenus engageants.</h3>
          <h3>
          Notre expertise ? Construire pour votre marque un écosystème digital complet, optimisé pour atteindre vos objectifs et renforcer votre présence en ligne. Que ce soit à travers des campagnes percutantes, des designs captivants ou des vidéos impactantes, nous donnons vie à votre histoire dans le monde numérique.
             </h3>
          <h3> 
          Basés en Suisse, en Espagne et au Maroc, nous sommes à vos côtés pour propulser votre marque, où que vous soyez.
          </h3>
          <h2> 
          Stratégie, créativité, résultats. Ensemble, développons votre potentiel digital.
          </h2>
          <Button as={Link} to="/contact-us" color="teal">
            Prenez contacte avec nous
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default HomeHero;
